import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import ProductService from 'services/ProductService';

export const initialState = {
	// loading: false,
	// message: '',
	// showMessage: false,
	// redirect: '',
	// token: localStorage.getItem(AUTH_TOKEN) || null
    products: [],
    selectedProduct: null,
    loading: false,
    error: null,
    message: '',
    showMessage: false,
}

export const fetchProducts = createAsyncThunk('products/fetchProducts', async (data, { rejectWithValue }) => {
      try {
        const response = await ProductService.getList(data);
        if (response.success) {
          return response.data; 
        } else {
          return rejectWithValue(response.message);
        }
      } catch (error) {
        return rejectWithValue(error.message || 'Error fetching products');
      }
    }
  );

// Tạo slice cho product
export const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
      showProductMessage: (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      },
      hideProductMessage: (state) => {
        state.message = '';
        state.showMessage = false;
      },
      resetProducts: (state) => {
        state.products = [];
        state.loading = false;
        state.error = '';
      },
      setSelectedProduct(state, action) {
        state.selectedProduct = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchProducts.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchProducts.fulfilled, (state, action) => {
          state.loading = false;
          state.products = action.payload;
        })
        .addCase(fetchProducts.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload || 'Failed to fetch products';
        });
    },
  });
  
  // Export các actions
  export const { showProductMessage, hideProductMessage, resetProducts , setSelectedProduct} = productSlice.actions;
  
  // Export reducer
  export default productSlice.reducer;
import { fetchStimeService } from "auth/FetchGC";

const DashboardService = {};

DashboardService.getList = function (params = {}) { 
    return fetchStimeService({
      url: "product/pbp/dashboard",
      method: "get",
      params,
    });
  };

  export default DashboardService;
  
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onSwitchTheme, toggleCollapsedNav } from "store/slices/themeSlice";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { getCache, setCache } from "utils/cacheService";

const useInitializeTheme = () => {
    const dispatch = useDispatch();
    const currentTheme = useSelector((state) => state.theme.currentTheme);
    const navCollapsed = useSelector((state) => state.theme.navCollapsed);
    const { switcher, themes } = useThemeSwitcher();

    const [isInitialized, setIsInitialized] = useState(false);
    
    useEffect(() => {
        const initializeConfig = async () => {
            const profileUid = localStorage.getItem("uid"); 
            if (profileUid && !isInitialized) {
                const userConfig = await getCache(profileUid);
                
                if (userConfig) {
                    // Áp dụng cấu hình từ Cache
                    dispatch(onSwitchTheme(userConfig.theme));
                    dispatch(toggleCollapsedNav(userConfig.navCollapsed));
                    if (themes[userConfig.theme]) {
                        switcher({ theme: userConfig.theme });
                    }
                } else {
                    // Cấu hình mặc định
                    const defaultConfig = { theme: "light", navCollapsed: false };
                    // Lưu vào Cache Storage
                    await setCache(profileUid, defaultConfig);
                    dispatch(onSwitchTheme(defaultConfig.theme));
                    dispatch(toggleCollapsedNav(defaultConfig.navCollapsed));
                    if (themes[defaultConfig.theme]) {
                        switcher({ theme: defaultConfig.theme });
                    }
                }
                setIsInitialized(true);
            }
        };

        initializeConfig();
    }, [dispatch, switcher, themes, isInitialized]);

    useEffect(() => {
        const updateConfig = async () => {
            const profileUid = localStorage.getItem("uid"); 
            if (profileUid && isInitialized) {
                const userConfig = {
                    theme: currentTheme,
                    navCollapsed,
                };
                // Lưu cấu hình mới vào Cache Storage khi thay đổi
                await setCache(profileUid , userConfig);
            }
        };

        updateConfig();
    }, [currentTheme, navCollapsed,isInitialized]);

    useEffect(() => {
        if (themes[currentTheme]) {
            switcher({ theme: currentTheme });
        }
    }, [currentTheme, switcher, themes]);
};

export default useInitializeTheme;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DashboardService from "services/DashboardService";

// Async action để gọi API
export const fetchDashboardData = createAsyncThunk(
  "dashboard/fetchDashboardData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await DashboardService.getList(params);
      if (response?.success) {
        // Trích xuất data từ payload của API
        return response.data;
      } else {
        return rejectWithValue("API không trả về success.");
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Slice định nghĩa reducer và các action
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    revenue: null,       // Object chứa thông tin doanh thu
    order: null,         // Object chứa thông tin đơn hàng
    chart: null,         // Object chứa thông tin biểu đồ
    loading: false,      // Trạng thái tải dữ liệu
    error: null,         // Lỗi nếu có
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Khi fetchDashboardData được kích hoạt
      .addCase(fetchDashboardData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // Khi fetchDashboardData thành công
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.loading = false;
        state.revenue = action.payload?.revenue || null;
        state.order = action.payload?.order || null;
        state.chart = action.payload?.chart || null;
      })
      // Khi fetchDashboardData thất bại
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default dashboardSlice.reducer;
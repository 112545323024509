import { createSlice } from '@reduxjs/toolkit'
import { THEME_CONFIG } from 'configs/AppConfig'

export const initialState = {
	...THEME_CONFIG, // giữ lại các giá trị từ cấu hình hiện có
	locale: localStorage.getItem('appLocale') || THEME_CONFIG.locale, 
	currentTheme: THEME_CONFIG.currentTheme,
	navCollapsed: THEME_CONFIG.navCollapsed,
};
export const themeSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
        toggleCollapsedNav: (state, action) => {
			state.navCollapsed = action.payload;
		},
        onNavStyleChange: (state, action) => {
			state.sideNavTheme = action.payload
		},
        onLocaleChange: (state, action) => {
			state.locale = action.payload;
		},
        onNavTypeChange: (state, action) => {
			state.navType = action.payload
		},
        onTopNavColorChange: (state, action) => {
			state.topNavColor = action.payload;
		},
        onHeaderNavColorChange: (state, action) => {
			state.headerNavColor = action.payload;
		},
        onMobileNavToggle: (state, action) => {
			state.mobileNav = action.payload
		},
        onSwitchTheme: (state, action) => {
			state.currentTheme = action.payload;
		},
        onDirectionChange: (state, action) => {
			state.direction = action.payload
		},
		onBlankLayout: (state, action) => {
			state.blankLayout = action.payload
		},
		resetNavCollapsed: (state) => {
            state.navCollapsed = initialState.navCollapsed 
        },
        resetTheme: (state) => {
            state.currentTheme = initialState.currentTheme
        },
	},
})

export const { 
    toggleCollapsedNav,
    onNavStyleChange,
    onLocaleChange,
    onNavTypeChange,
    onTopNavColorChange,
    onHeaderNavColorChange,
    onMobileNavToggle,
    onSwitchTheme,
    onDirectionChange,
	onBlankLayout,
	resetNavCollapsed, 
	resetTheme,
	resetThemeConfig,
	setThemeConfig
} = themeSlice.actions

export default themeSlice.reducer
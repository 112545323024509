import { fetchStimeService } from "auth/FetchGC";

const ProductService = {};

ProductService.getList = function (data) {
  return fetchStimeService({
    url: `/product/pbp`, 
    method: "get",       
    params: data,        
  });
};

ProductService.getOrderList = function (params = {}) { 
  return fetchStimeService({
    url: "/product/pbp/orders",
    method: "get",
    params: params, 
  });
};

ProductService.getExportOrderList = function (params = {}) { 
  return fetchStimeService({
    url: "/product/pbp/orders/export",
    method: "get",
    params: params, 
  });
};


export default ProductService;
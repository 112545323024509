import antdEnUS from 'antd/es/locale/en_US';
import antdViVN from 'antd/es/locale/vi_VN';
import en from './locales/en_US.json'
import vi from './locales/vi_VN.json'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { THEME_CONFIG } from 'configs/AppConfig';

// Lấy ngôn ngữ lưu trữ từ localStorage hoặc sử dụng mặc định từ THEME_CONFIG
const savedLocale = localStorage.getItem('appLocale') || THEME_CONFIG.locale;

export const resources = {
    en: {
        translation: en,
        antd: antdEnUS
    },
    vi: {
        translation: vi,
        antd: antdViVN
    },
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: THEME_CONFIG.locale,
    // lng: THEME_CONFIG.locale,
    lng: savedLocale,
    interpolation: {
        escapeValue: false 
    }
});

export default i18n;
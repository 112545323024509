const CACHE_NAME = "user-config-cache";

/**
 * Lưu dữ liệu vào cache storage
 * @param {String} key 
 * @param {Object} data 
 */
export const setCache = async (key, data) => {
  const cache = await caches.open(CACHE_NAME);
  const cacheResponse = new Response(JSON.stringify(data));
  const cacheRequest = new Request(`/${key}`);
  await cache.put(cacheRequest, cacheResponse);
};

/**
 * Lấy dữ liệu từ cache storage
 * @param {String} key
 * @returns {Object|null}
 */
export const getCache = async (key) => {
  const cache = await caches.open(CACHE_NAME);
  const cacheRequest = new Request(`/${key}`);
  const cachedResponse = await cache.match(cacheRequest);

  if (cachedResponse) {
    return await cachedResponse.json();
  }
  return null;
};

/**
 * Xóa dữ liệu trong cache storage
 * @param {String} key
 */
export const removeCache = async (key) => {
  const cache = await caches.open(CACHE_NAME);
  const cacheRequest = new Request(`/${key}`);
  await cache.delete(cacheRequest);
};

/**
 * Xóa toàn bộ cache
 */
export const clearCache = async () => {
  await caches.delete(CACHE_NAME);
};
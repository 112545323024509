import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/AuthService';
import AuthService from 'services/AuthService';
import { fetchUserProfile } from "./profileSlice";
import { useSelector, useDispatch } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { toggleCollapsedNav,onSwitchTheme,resetNavCollapsed, resetTheme } from "store/slices/themeSlice";
import { getCache, setCache } from 'utils/cacheService';
export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
	avatarUrl: localStorage.getItem('avatarUrl') || '',
	username: localStorage.getItem('username') || '',
	name: localStorage.getItem('name') || '',
	email: localStorage.getItem('email') || '',
}

export const signIn = createAsyncThunk('auth/signIn', async (data, {dispatch, rejectWithValue, getState}) => {
	const { username, password } = data;
	try {
		const response = await AuthService.login({ username, password });
		if (response.success) {
			const token = response.data.accessToken;
			localStorage.setItem(AUTH_TOKEN, token);

			await dispatch(fetchUserProfile());

			const state = getState();
			const profile = state.profile.profile;
			if (profile && profile.uid) {
				localStorage.setItem('uid', profile.uid);
				const existingConfig = await getCache(profile.uid); 
				
				if (existingConfig) {
				if (typeof existingConfig === "string") {
					existingConfig = JSON.parse(existingConfig);
				}
					dispatch(onSwitchTheme(existingConfig.theme));
					dispatch(toggleCollapsedNav(existingConfig.navCollapsed));
				} else {
				  const defaultConfig = {
					theme: 'light',
					navCollapsed: false,
				  };
				await setCache(profile.uid, defaultConfig);
				dispatch(onSwitchTheme(defaultConfig.theme));
				dispatch(toggleCollapsedNav(defaultConfig.navCollapsed));
				}
			  }

			return token;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const signUp = createAsyncThunk('auth/signUp', async (data, { rejectWithValue }) => {
	const { email, password } = data;
	try {
		const response = await FirebaseService.signUpEmailRequest(email, password);
		if (response.user) {
			const token = response.user.refreshToken;
			localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
			return token;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const signOut = createAsyncThunk('auth/signOut', async (_, { dispatch }) => {
	const response = await FirebaseService.signOutRequest();
	localStorage.removeItem(AUTH_TOKEN);
	localStorage.removeItem('uid');

	dispatch(signOutSuccess());
	return response.data;
});

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
	const response = await FirebaseService.signInGoogleRequest();
	if (response.user) {
		const token = response.user.refreshToken;
		localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
		return token;
	} else {
		return rejectWithValue(response.message?.replace('Firebase: ', ''));
	}
});

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
	const response = await FirebaseService.signInFacebookRequest();
	if (response.user) {
		const token = response.user.refreshToken;
		localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
		return token;
	} else {
		return rejectWithValue(response.message?.replace('Firebase: ', ''));
	}
});

export const authSlice = createSlice({
	name: 'auth',
	initialState: {
		loading: false,
		message: '',
		showMessage: false,
		redirect: '',
		token: localStorage.getItem(AUTH_TOKEN) || null,
		avatarUrl: localStorage.getItem('avatarUrl') || '', 
		username: localStorage.getItem('username') || '', 
		name: localStorage.getItem('name') || '',
		email: localStorage.getItem('email') || '',
	},
	reducers: {
		setName: (state, action) => {
			state.name = action.payload;
			localStorage.setItem('name', action.payload);
		},
		setEmail: (state, action) => {
			state.email = action.payload;
			localStorage.setItem('email', action.payload);
		},
		setAvatarUrl: (state, action) => {
			state.avatarUrl = action.payload;
			localStorage.setItem('avatarUrl', action.payload);
		},
		setUsername: (state, action) => {
			state.username = action.payload;
			localStorage.setItem('username', action.payload); 
		},
		authenticated: (state, action) => {
			state.loading = false;
			state.redirect = '/';
			state.token = action.payload;
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload;
			state.showMessage = true;
			state.loading = false;
		},
		hideAuthMessage: (state) => {
			state.message = '';
			state.showMessage = false;
		},
		signOutSuccess: (state) => {
			state.loading = false;
			state.token = null;
			state.redirect = '/';
			localStorage.removeItem(AUTH_TOKEN);
			storage.removeItem('persist:root'); // Clear Redux-Persist
			state = undefined;
		},
		showLoading: (state) => {
			state.loading = true;
		},
		signInSuccess: (state, action) => {
			state.loading = false;
			state.token = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true;
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/';
				state.token = action.payload;
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false;
				state.token = null;
				state.redirect = '/';
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false;
				state.token = null;
				state.redirect = '/';
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true;
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/';
				state.token = action.payload;
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true;
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/';
				state.token = action.payload;
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true;
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/';
				state.token = action.payload;
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			});
	}
});

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	setAvatarUrl,
	setUsername,
	setName,
	setEmail,
	signInSuccess
} = authSlice.actions;

export default authSlice.reducer;